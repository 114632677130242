import React, { useCallback, useEffect, useState } from "react";
import MetamaskIcon from "../../../img/metamask-icon.png";
import { ethers, Interface } from "ethers";
import Comm from "../../../utils/Comm";
import { useSDK } from "@metamask/sdk-react-ui";
import settings from "../../../config";

const TOKEN_CONTRACT_ADDRESS = settings.config.tokenContractAddress;

const TARGET_NETWORK = settings.config.chain;

const Metamask = ({
  amount,
  currency,
  onComplete,
  startCheckingPayment,
  deposit = null,
}) => {
  const { sdk, connected, account } = useSDK();
  const [responseMessage, setResponseMessage] = useState(null);
  const [isError, setError] = useState(false);
  const [transactionProcessing, setTransactionProcessing] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(null);
  const [network, setNetwork] = useState(null);

  const purchaseStr = localStorage.getItem("purchase");
  const purchase = purchaseStr ? JSON.parse(purchaseStr) : null;

  const fetchCryptoData = useCallback(async () => {
    try {
      const response = await Comm.request({
        method: "get",
        url: `/crypto?symbol=${currency}`,
      });
      setTransactionAmount(
        currency === "USDT" || currency === "USDC" || currency === "ETH"
          ? Number(amount)
          : Number(purchase?.usd) / Number(response.data.usd)
      );
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  }, [currency, purchase, amount]);

  useEffect(() => {
    fetchCryptoData();
  }, [fetchCryptoData]);

  useEffect(() => {
    if (deposit && connected && transactionAmount) {
      paymentRequest();
    }
  }, [deposit, connected, transactionAmount]);

  const switchNetwork = async () => {
    try {
      const provider = sdk?.getProvider();
      console.log("provider", provider);
      console.log("TARGET_NETWORK.chainId", TARGET_NETWORK.chainId);
      if (!provider) throw new Error("Metamask provider not found");
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: TARGET_NETWORK.chainId }],
      });
      const networkData = await provider.request({ method: "eth_chainId" });
      setNetwork(
        networkData === TARGET_NETWORK.chainId
          ? "Sepolia Testnet"
          : "Ethereum Mainnet"
      );
    } catch (error) {
      console.error("Network switch error:", error);
      if (error.code === 4902) {
        try {
          await sdk?.getProvider().request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: TARGET_NETWORK.chainId,
                chainName: TARGET_NETWORK.chainName,
                nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
                rpcUrls: ["https://rpc.sepolia.org"],
              },
            ],
          });
        } catch (addError) {
          console.error("Failed to add network:", addError);
        }
      }
    }
  };

  const connectWallet = async () => {
    try {
      await sdk?.getProvider().request({ method: "eth_requestAccounts" });
      await switchNetwork();
    } catch (error) {
      console.error("Connection error:", error);
      setResponseMessage(error.message);
      resetStates();
    }
  };

  const resetStates = () => {
    setResponseMessage(null);
    setTransactionProcessing(false);
    setError(false);
    onComplete();
  };

  const disconnectWallet = () => sdk.terminate();

  const paymentRequest = async () => {
    if (!transactionAmount || !deposit || !account) return;
    setTransactionProcessing(true);

    try {
      const provider = sdk?.getProvider();
      if (!provider) throw new Error("Metamask provider not found");

      const networkData = await provider.request({ method: "eth_chainId" });
      if (TARGET_NETWORK.chainId !== networkData) {
        await switchNetwork();
      }

      if (!network) {
        const networkData = await provider.request({ method: "eth_chainId" });
        setNetwork(
          networkData === TARGET_NETWORK.chainId
            ? "Sepolia Testnet"
            : "Ethereum Mainnet"
        );
      }

      let params = [];
      if (currency === "ETH") {
        const weiAmount = ethers.parseEther(
          transactionAmount.toFixed(6).toString()
        );
        params = [
          {
            from: account,
            to: deposit.address,
            value: Number(weiAmount).toString(16),
          },
        ];
      } else if (currency === "USDT" || currency === "USDC") {
        const usdtInterface = new Interface([
          "function transfer(address to, uint256 value) public returns (bool)",
        ]);
        const data = usdtInterface.encodeFunctionData("transfer", [
          deposit.address,
          ethers.parseUnits(transactionAmount.toString(), 6),
        ]);
        params = [{ from: account, to: TOKEN_CONTRACT_ADDRESS, data }];
      }

      await provider.request({ method: "eth_sendTransaction", params });
      startCheckingPayment();
    } catch (error) {
      console.error("Transaction error:", error);
      setResponseMessage(error.message);
      setError(true);
    } finally {
      setTransactionProcessing(false);
    }
  };

  return (
    <div>
      {!connected ? (
        <button
          onClick={connectWallet}
          className="metamask-btn d-flex gap-2 w-100"
        >
          <img src={MetamaskIcon} height={30} alt="Metamask" /> Connect Metamask
        </button>
      ) : (
        <div className="d-flex flex-column gap-4">
          <div className="crypto-connected-account">
            <button
              onClick={disconnectWallet}
              className="btn btn-link p-0 mt-3"
            >
              Disconnect Wallet
            </button>
            <ul className="connected-account ps-0">
              <li className="d-flex justify-content-between align-items-center pb-0">
                <span className="label">- Connected Account</span>
                <p className="account-id">{account}</p>
              </li>
              <li className="d-flex justify-content-between align-items-center pt-0 pb-0">
                <span className="label">- Network</span>
                <p className="account-id">{network || "Unknown"}</p>
              </li>
            </ul>
          </div>
          <button
            disabled={!transactionAmount || !deposit || transactionProcessing}
            onClick={paymentRequest}
            className="btn btn-lg py-3 btn-primary d-flex gap-2 w-100 justify-content-center align-items-center"
          >
            <img src={MetamaskIcon} height={30} alt="Metamask" />{" "}
            {transactionProcessing ? "Processing..." : "Pay with Metamask"}
          </button>
          {isError && <p className="text-danger">{responseMessage}</p>}
        </div>
      )}
    </div>
  );
};

export default React.memo(Metamask);
